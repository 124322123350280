import { useCallback, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { Button } from "..";

import MobileMenu from "./MobileMenu";
import ProfileDropdown from "./ProfileDropdowm";
import { Close, HamburgerMenu } from "../../Icons";

import { useOutsideAlerter } from "../../Hooks";
import { useUserContext } from "../../../providers/UserProvider";

function Navbar() {
	const navigate = useNavigate();
	const { pathname } = useLocation();

	const wrapperProfile = useRef(null);
	const wrapperMobileMenu = useRef(null);

	const { user, isAuthenticated } = useUserContext();

	const [abbr, setAbbr] = useState<string>("");
	const [toggleProfile, setToggleProfile] = useState<boolean>(false);
	const [toggleMobileMenu, setToggleMobileMenu] = useState<boolean>(false);

	useEffect(() => {
		if (user && isAuthenticated) {
			const { firstName, lastName } = user;

			const abbreviation = `${
				firstName.length > 0 ? firstName.charAt(0) : "N"
			}${lastName.length > 0 ? lastName.charAt(0) : "N"}`;

			setAbbr(abbreviation);
		} else setAbbr("LF");
	}, [user, isAuthenticated]);

	const handleOutsideProfileClick = useCallback(
		(outsideClick: boolean) => {
			if (outsideClick && toggleProfile) setToggleProfile(false);
		},
		[toggleProfile]
	);
	useOutsideAlerter(wrapperProfile, handleOutsideProfileClick);

	const handleOutsideMobileMenuClick = useCallback(
		(outsideClick: boolean) => {
			if (outsideClick && toggleMobileMenu) setToggleMobileMenu(false);
		},
		[toggleMobileMenu]
	);
	useOutsideAlerter(wrapperMobileMenu, handleOutsideMobileMenuClick);

	const handleActivePath = useCallback(
		(path: string) => {
			navigate(path, {});
			setToggleMobileMenu(false);
		},
		[navigate]
	);

	const activeStyle = "text-white bg-green-900";
	const inactiveStyle = "text-sm text-green-300 hover:text-white";

	return (
		<nav className="fixed w-full bg-green-600">
			<div className="px-2 mx-auto max-w-7xl sm:px-6 lg:px-8">
				<div className="h-16 flex relative items-center justify-between">
					{isAuthenticated && (
						<div className="flex left-0 absolute inset-y-0 items-center sm:hidden">
							<Button
								type="button"
								padding={false}
								onClick={() => setToggleMobileMenu(!toggleMobileMenu)}
								className="
                  p-2
                  rounded-md
                  inline-flex
                  items-center
                  justify-center
                  text-white
                  hover:bg-green-600
                "
							>
								{toggleMobileMenu ? (
									<Close color="stroke-current" />
								) : (
									<HamburgerMenu color="stroke-current" />
								)}
							</Button>
						</div>
					)}
					<div
						className="
              flex
              flex-1
              items-center
              justify-center
              sm:items-stretch
              sm:justify-start
            "
					>
						<div className="flex items-center flex-shrink-0">
							<Button
								padding={false}
								onClick={() => navigate("/", {})}
								className="text-3xl font-bold text-white"
							>
								Lost&Found
							</Button>
						</div>
						{isAuthenticated && (
							<div className="hidden sm:ml-6 sm:block">
								<div className="flex space-x-4">
									<Button
										onClick={() => handleActivePath("/app/qr")}
										className={`
                        rounded-md
                        font-medium
                        ${
													pathname.includes("/app/qr")
														? activeStyle
														: inactiveStyle
												}
                      `}
									>
										QR Code List
									</Button>
									<Button
										onClick={() => handleActivePath("/app/partner")}
										className={`
                        rounded-md
                        font-medium
                        ${
													pathname.includes("/app/partner")
														? activeStyle
														: inactiveStyle
												}
									    `}
									>
										Rent A Car
									</Button>
								</div>
							</div>
						)}
					</div>

					<div
						className="
              pr-2
              flex
              right-0
              absolute
              inset-y-0
              items-center
              sm:pr-0
              sm:ml-6
              sm:static
              sm:inset-auto
            "
					>
						<div ref={wrapperProfile} className="ml-3 relative">
							<Button
								type="button"
								padding={false}
								id="user-menu-button"
								onClick={() => setToggleProfile(!toggleProfile)}
								className="
                  h-8
                  w-8
                  flex
                  border-2
                  border-white
                  items-center
                  rounded-full
                  justify-center
                  focus:outline-none
                  focus:border-offset-2
                  focus:border-offset-green-800
                "
							>
								<span className="font-bold text-white">{abbr}</span>
							</Button>
							<ProfileDropdown
								show={toggleProfile}
								isAuthenticated={isAuthenticated}
								setToggleProfile={setToggleProfile}
							/>
						</div>
					</div>
				</div>
			</div>

			<MobileMenu
				activePath={pathname}
				show={toggleMobileMenu}
				ref={wrapperMobileMenu}
				handleActivePath={handleActivePath}
			/>
		</nav>
	);
}

export default Navbar;
