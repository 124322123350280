type DividerProps = {
	text?: string;
	className?: string;
};

function Divider({ className, text = "+" }: DividerProps) {
	return (
		<div className={`py-5 flex items-center ${className}`}>
			<div className="border-t flex-grow border-current"></div>
			<span className="mx-4 flex-shrink text-current">{text}</span>
			<div className="border-t flex-grow border-current"></div>
		</div>
	);
}

export default Divider;
