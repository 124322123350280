type HamburgerMenuProps = {
	color?: string;
	size?: number;
};

function HamburgerMenu({
	color = "stroke-black",
	size = 25,
}: HamburgerMenuProps) {
	return (
		<svg
			fill="none"
			width={size}
			height={size}
			strokeWidth="1.5"
			viewBox="0 0 24 24"
			data-testid="hamburger-icon"
		>
			<path
				className={color}
				data-testid="hamburger-icon-path"
				strokeLinecap="round"
				strokeLinejoin="round"
				d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
			/>
		</svg>
	);
}

export default HamburgerMenu;
