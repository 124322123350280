import { HTMLAttributeAnchorTarget, MouseEvent, ReactNode } from "react";

import Button from "./Button";
import { Link as LinkIcon } from "../Icons";

type LinkProps = {
	className?: string;
	customIcon?: ReactNode;
	disabled?: boolean;
	href?: string;
	icon?: boolean;
	iconPosition?: "start" | "end";
	iconSize?: number;
	id?: string;
	onClick?: (event?: MouseEvent<HTMLButtonElement>) => void;
	padding?: boolean;
	tabIndex?: number;
	target?: HTMLAttributeAnchorTarget;
	text?: string;
	textClassName?: string;
	type?: "submit" | "reset" | "button";
};

function Link({
	customIcon,
	disabled,
	href,
	icon = false,
	iconPosition = "end",
	iconSize = 20,
	id,
	onClick,
	tabIndex,
	target = "_self",
	text,
	textClassName,
	type,
}: LinkProps) {
	const renderIcon = () => (
		<div className="ml-1 self-center">
			{customIcon || <LinkIcon size={iconSize} color="fill-current" />}
		</div>
	);

	if (!onClick)
		return (
			<a
				id={id}
				href={href}
				target={target}
				tabIndex={tabIndex}
				data-testid="external-link"
				rel="noreferrer"
				className={`
          flex
          flex-row
          space-x-2
          items-center
          hover:underline
          text-green-600
          hover:text-green-800
          ${disabled ? "opacity-40 cursor-not-allowed pointer-events-none" : ""}
        `}
			>
				{icon && iconPosition === "start" && renderIcon()}
				<span className={textClassName}>{text}</span>
				{icon && iconPosition === "end" && renderIcon()}
			</a>
		);

	return (
		<Button
			id={id}
			type={type}
			padding={false}
			onClick={onClick}
			disabled={disabled}
			tabIndex={tabIndex}
			data-testid="internal-link"
			className={`
        flex
        flex-row
        space-x-2
        items-center
        hover:underline
        text-green-600
        hover:text-green-800
        ${disabled ? "opacity-40 cursor-not-allowed pointer-events-none" : ""}
      `}
		>
			{icon && iconPosition === "start" && renderIcon()}
			<span className={textClassName}>{text}</span>
			{icon && iconPosition === "end" && renderIcon()}
		</Button>
	);
}

export default Link;
