type LinkProps = {
	color?: string;
	size?: number;
};

function Link({ color = "fill-black", size = 25 }: LinkProps) {
	return (
		<svg
			fill="none"
			width={size}
			height={size}
			viewBox="0 0 15 15"
			data-testid="link-icon"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				className={color}
				data-testid="link-icon-path"
				d="M8.27251 0L10.2364 1.96393C8.36224 3.83812 4.38474 7.81626 4.19064 8.00972C3.69361 8.50675 3.69361 9.31243 4.19064 9.80946C4.68767 10.3065 5.49335 10.3065 5.99038 9.80946C6.18385 9.61536 10.1613 5.63786 12.0362 3.76367L14.0001 5.72759V0H8.27251Z"
			/>
			<path
				className={color}
				data-testid="link-icon-path"
				d="M12.0916 7.63743C11.7397 7.63743 11.4552 7.9219 11.4552 8.27383V12.7286H1.2728V2.54624H5.72759C6.07889 2.54624 6.36399 2.26177 6.36399 1.90984C6.36399 1.55791 6.07889 1.27344 5.72759 1.27344H1.2728C0.570214 1.27344 0 1.84301 0 2.54624V12.7286C0 13.4318 0.570214 14.0014 1.2728 14.0014H11.4552C12.1578 14.0014 12.728 13.4318 12.728 12.7286V8.27383C12.728 7.9219 12.4435 7.63743 12.0916 7.63743Z"
			/>
		</svg>
	);
}

export default Link;
