import { ChangeEvent } from "react";

import { Check } from "../../Icons";

import { InputProps } from "./types";

type CheckboxProps = InputProps & {
	checked: boolean;
	half?: boolean;
	onChange: (event: ChangeEvent<HTMLInputElement>) => void;
};

function Checkbox({
	checked,
	disabled,
	half,
	label,
	name,
	onChange,
	required,
	textCapitalize = true,
	textUpper,
}: CheckboxProps) {
	return (
		<label
			className={`
        py-2
        flex
        flex-1
        flex-row
        items-center
        ${disabled ? "opacity-40 cursor-not-allowed" : "cursor-pointer"}
      `}
		>
			<div
				className={`
          w-5
          h-5
          mr-2
          flex
          border
          relative
          bg-white
          rounded-md
          items-center
          flex-shrink-0
          justify-center
          ${!disabled ? "hover:border-green-600" : ""}
          ${checked ? "border-green-600" : "border-black"}
        `}
			>
				<input
					id={name}
					name={name}
					type="checkbox"
					aria-label={name}
					onChange={onChange}
					disabled={disabled}
					defaultChecked={checked}
					className={`
            sr-only
            absolute
            font-medium
            outline-none
            ${disabled ? "pointer-events-none" : ""}
          `}
				/>
				{checked && <Check color="fill-green-600" />}
				{half && !checked && (
					<span className="w-2 h-1 border-b-2 rounded-full border-green-600"></span>
				)}
			</div>
			<span
				className={`
          ml-2
          flex
          flex-row
          ${textCapitalize && "capitalize"}
          ${textUpper ? "uppercase" : "normal-case"}
        `}
			>
				{label}
				{required && <div className="px-1 font-bold text-green-600">*</div>}
			</span>
		</label>
	);
}

export default Checkbox;
