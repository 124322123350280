import { InputProps } from "./types";

type TextProps = InputProps & {
	onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

function Text({
	disabled,
	errors,
	id,
	label,
	name,
	onChange,
	placeholder,
	required,
	subLabel,
	textCapitalize = true,
	textUpper,
	type,
	value,
}: TextProps) {
	return (
		<div className="py-2">
			<div
				className={`
          flex
          flex-1
          flex-col
          ${disabled && "opacity-40"}
          ${textCapitalize ? "capitalize" : "normal-case"}
        `}
			>
				{label && (
					<span
						className={`
              flex
              flex-row
              text-left
              font-semibold
              text-green-600
              ${textUpper && "uppercase"}
            `}
					>
						{label}
						{required && <div className="px-1 font-bold">*</div>}
					</span>
				)}
				{subLabel && <span className="text-black font-medium">{subLabel}</span>}
			</div>
			<div
				className={`
          p-2
          flex
          border-2
          flex-row
          rounded-md
          min-h-3rem
          border-green-600
          ${label && "mt-2"}
          ${
						disabled
							? "opacity-40 cursor-not-allowed pointer-events-none"
							: name &&
							  (value || (errors && errors[name])) &&
							  errors &&
							  errors[name]
							? "border-red-600"
							: "focus-within:border-green-800 focus-within:shadow-lg"
					}
        `}
			>
				<input
					id={id}
					type={type}
					name={name}
					value={value}
					aria-label={name}
					onChange={onChange}
					disabled={disabled}
					autoCapitalize="none"
					placeholder={placeholder}
					className={`
            px-2
            w-full
            font-medium
            text-black
            outline-none
            ${disabled ? "pointer-events-none" : ""}
          `}
				/>
			</div>
		</div>
	);
}

export default Text;
