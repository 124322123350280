import { InputProps } from "./types";

type ToggleProps = InputProps & {
	checked: boolean;
	onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

function Toggle({
	checked,
	disabled,
	label,
	name,
	onChange,
	required,
	textCapitalize = true,
	textUpper,
	value,
}: ToggleProps) {
	return (
		<label
			className={`
        py-2
        flex
        flex-1
        flex-row
        items-center
        ${disabled ? "opacity-40 cursor-not-allowed" : "cursor-pointer"}
      `}
		>
			<div
				className={`
          h-6
          w-11
          mr-2
          flex
          border
          relative
          rounded-full
          items-center
          ${value ? "bg-green-600" : "bg-white"}
          ${!disabled ? "hover:border-green-600" : ""}
          ${value ? "border-green-600" : "border-black"}
        `}
			>
				<input
					id={name}
					name={name}
					value={value}
					type="checkbox"
					aria-label={name}
					checked={checked}
					onChange={onChange}
					disabled={disabled}
					className={`
            sr-only
            font-medium
            outline-none
            ${disabled ? "pointer-events-none" : ""}
          `}
				/>
				<div
					style={{ marginTop: 1 }}
					className={`
            h-4
            w-4
            left-1
            top-0.5
            absolute
            shadow-sm
            transition
            rounded-full
            ${!value ? "bg-green-600" : "bg-white"}
            ${value ? "translate-x-full" : "translate-x-0"}
          `}
				></div>
			</div>
			<span
				className={`
          ml-2
          flex
          flex-row
          text-left
          font-semibold
          ${textCapitalize && "capitalize"}
          ${textUpper ? "uppercase" : "normal-case"}
        `}
			>
				{label}
				{required && <div className="px-1 font-bold text-green-600">*</div>}
			</span>
		</label>
	);
}

export default Toggle;
