type CloseProps = {
	color?: string;
	size?: number;
};

function Close({ color = "stroke-black", size = 25 }: CloseProps) {
	return (
		<svg
			fill="none"
			width={size}
			height={size}
			strokeWidth="1.5"
			viewBox="0 0 24 24"
			data-testid="close-icon"
		>
			<path
				className={color}
				data-testid="close-icon-path"
				strokeLinecap="round"
				strokeLinejoin="round"
				d="M6 18L18 6M6 6l12 12"
			/>
		</svg>
	);
}

export default Close;
